import React from "react";
import loadable from '@loadable/component'

const AsyncPage = loadable(props => import(`./Containers/${props.page}`), {
  cacheKey: props => props.page,
})

type ModalManagerProps = {
  modalId: string
};

const ModalManager = ({ modalId, ...rest }: ModalManagerProps) => {
  return (
      <AsyncPage page={modalId} {...rest} />
  )
}

export default ModalManager;