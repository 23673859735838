import React from "react";

import _move from "../lib/move";
import componentMount from "../lib/componentMount"
import ModalManager from "../components/ModalManager";

const _attachList = [
  {
    attach: self,
    list: [
      //components
      "ModalManager"
    ]
  }
]

const prepComponents = {
  ModalManager: componentMount(props => <ModalManager {...props} />),
};

_move(prepComponents, _attachList);